<template>
  <div class="relative">
    <div class="absolute inset-0">
      <img class="w-full h-full object-cover" :src="formatOcImage(src)" alt="" />
      <div
        class="absolute inset-0 bg-gradient-to-r from-accent-grad to-transparent opacity-80"
        aria-hidden="true"
      />
      <div :class="`absolute inset-0 ${blueBG ? 'bg-accent-dark' : 'bg-transparent'} `"></div>
    </div>
    <Container class="header-padding">
      <div class="py-8">
        <h1
          class="text-4xl font-semibold tracking-wide text-white sm:text-5xl uppercase"
        >
          {{ title }}
        </h1>
        <p v-if="description" class="mt-6 text-xl text-indigo-100 max-w-3xl">
          {{ description }}
        </p>
      </div>
    </Container>
  </div>
  
    <div class="bg-accent-lightest p-8 hidden md:block">
      <div class="lg:max-w-7xl lg:mx-auto px-4 sm:px-6 lg:px-8 flex space-x-5 items-center text-sm md:text-base">
        <router-link to="/">
          <HomeIcon class="w-5 h-5 text-accent" />
        </router-link>
        <div v-if="parent != null">/</div>
        <span  v-if="parent != null" class="uppercase text-accent">{{ parent }}</span>
        <div>/</div>
        <span class="uppercase text-accent-dark">{{ title }}</span>
      </div>
    </div>
  <!-- <Container class="sm:py-5 sm:pb-0 -mb-12">
  <nav class="flex" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a href="#" class="text-accent hover:text-accent-dark group">
            <svg class="flex-shrink-0 h-5 w-5 opacity-75 group-hover:opacity-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li v-if="parent != null">
        <div class="flex items-center">
          <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
          <a href="#" class="ml-4  uppercase text-base font-medium text-gray-500 hover:text-gray-700 text-accent underline hover:no-underline">
          {{ parent }}</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
          <span  class="ml-4 uppercase  text-base font-medium text-gray-900">
          {{ title }}</span>
        </div>
      </li>
    </ol>
  </nav>
</Container> -->
</template>

<script>
import Container from "@/components/layouts/Container.vue";
import {OcContentService } from '@/services/ocContentService';

import { HomeIcon } from "@heroicons/vue/solid";
export default {
  components: { Container, HomeIcon },
  name: "Header",
  props: {
    title: String,
    description: String,
    parent: String,
    src: String
  },
  computed: {
    blueBG() {
      const { path } = this.$route;
      return path.includes("/about/our-partners");
    },
  },
      methods: {
    formatOcImage(imagePath) {
      return OcContentService.formatImage(imagePath);
    },
      }
};
</script>
