<template>
 <Header v-if="heroData" :title="heroData[0].displayText" :src="heroData[0].image.paths[0]"  />
  <Container v-if="sectionData">
    <div class="divide-y-2 divide-accent-dark">
      <div class="grid gap-8 md:grid-cols-3 py-12 pt-0">
        <div>
          <h2 class="text-4xl">Contact Information</h2>
        </div>
        <div>
          <h3 class="text-offblack text-xl capitalize mb-2">{{sectionData[0].mainText}}</h3>
          <address class="not-italic" v-html="sectionData[0].content.html">
           
          </address>
          <h3 class="text-offblack text-xl capitalize mb-2 mt-6">{{sectionData[1].displayText}}</h3>
          <a class="text-accent underline" :href="`tel:` + sectionData[1].mainText">{{sectionData[1].mainText}}</a>
        </div>
        <div>
          <h3 class="text-offblack text-xl capitalize mb-2">{{sectionData[2].displayText}}</h3>
          <div class="space-y-2">
            <a class="block text-accent underline" :href="`tel:` + sectionData[2].mainText">{{sectionData[2].mainText}}</a>
            <a class="block text-accent underline" :href="`tel:` + sectionData[3].mainText">{{sectionData[3].mainText}}</a>
          </div>
          <h3 class="text-offblack text-xl capitalize mb-2 mt-6">Social Media</h3>
          <div class="flex items-center space-x-5">
            <a :href="sectionData[4].button1Info.url"><img src="@/assets/twitter.svg" alt="" /></a>
            <a :href="sectionData[5].button1Info.url"><img src="@/assets/linkedin.svg" alt="" /></a>
            <a :href="sectionData[6].button1Info.url"><img src="@/assets/instagram.svg" alt="" /></a>
           
          </div>
        </div>
      </div>
      <div class="grid gap-8 md:grid-cols-3 py-12">
        <div>
          <h2 class="text-4xl">Membership</h2>
        </div>
        <div>
          <h3 class="text-offblack text-xl capitalize mb-2">{{sectionData[7].displayText}}</h3>
          <p v-html="sectionData[7].content.html"></p>
          <a class="text-accent underline" :href="`mailto:` + sectionData[7].mainText">{{sectionData[7].mainText}}</a>
        </div>
      </div>
      <div class="grid gap-8 md:grid-cols-3 py-12">
        <div>
          <h2 class="text-4xl">Media</h2>
        </div>
        <div>
          <h3 class="text-offblack text-xl capitalize mb-2">{{sectionData[8].displayText}}</h3>
          <p v-html="sectionData[8].content.html"></p>
          <a class="text-accent underline" :href="`mailto:` + sectionData[8].mainText">{{sectionData[8].mainText}}</a>
        </div>
      </div>
    </div>
  </Container>
  <div class="h-80 w-full object-cover">
    <iframe class="w-full h-full object-cover" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.7022714735494!2d-79.48622748449682!3d43.820534679115966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2ebd713bdf0f%3A0x75b90270c46e593a!2s25%20N%20Rivermede%20Rd%20Unit%2013%2C%20Concord%2C%20ON%20L4K%205V4!5e0!3m2!1sen!2sca!4v1649709898864!5m2!1sen!2sca" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
</template>

<script>
import Container from "../../components/layouts/Container.vue";
import Header from "@/components/base/Header";
import {OcContentService } from '@/services/ocContentService';

export default {
  components: {
    Header,
    Container,
  },
  data() {
    return {
      heroData: null,
      sectionData: null,

    };
  },
    async created() {
    this.fetchOcData();
  },
    methods: {
    async fetchOcData() {
      this.heroData = await OcContentService.fetchPageData("contact", "HeroImage");
      this.sectionData = await OcContentService.fetchPageData("contact", "SectionContent");
    },
      formatOcImage(imagePath) {
      return OcContentService.formatImage(imagePath);
    },
    },
    filters: {
    strippedContent: function(string) {
           return string.replace(/<[^>]+>/g, ""); 
    }
}
};
</script>
